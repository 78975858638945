import gql from 'graphql-tag';

/**
 * Define All client queries here
 */

export const GET_HR_REQUESTS_OPTIONS_QUERY = gql`
  query GetHrRequestOptions {
    getHrRequestOptions {
      groups {
        key
        icon
        title
      }
      options {
        title
        description
        popular {
          order
          title
          description
        }
        groupKey
        type
        subType
        slug
        audience
        requiresPrimaryHrm
        expectation {
          cycleTime
          description
        }
        keywords
        unlisted
      }
    }
  }
`;

export const GET_HR_REQUESTS_QUERY = gql`
  query GetHrRequests {
    getHrRequests {
      hrRequests {
        id
      }
    }
  }
`;

export const GET_HR_REQUEST_QUERY = gql`
  query HrRequest($input: GetHrRequestByIdInput!) {
    hrRequest(input: $input) {
      id
      conversation {
        id
        intercomConversationId
        subject

        messages {
          body
          inBound
        }
      }
    }
  }
`;

export const GET_ONBOARDING_CALENDLY_LINK_QUERY = gql`
  query GetOnboardingCalendlyConfig($input: OnboardingCalendlyConfigInput!) {
    getOnboardingCalendlyConfig(input: $input) {
      link
      tierNumber
      zone
    }
  }
`;

export const GET_CONVERSATION_BY_INTERCOM_ID_QUERY = gql`
  query GetConversationByIntercomId($input: GetConversationByIntercomIdInput!) {
    getConversationByIntercomId(input: $input) {
      id
      intercomConversationId
      messages {
        createdAt
        body
        inBound
        attachments {
          type
          name
          contentType
          url
        }
      }
      subject
      createdAt
      inBound
    }
  }
`;

export const GET_HR_REQUEST_LIST = gql`
  query GetHrRequestList {
    hrRequestList {
      hrRequests {
        caseId
        caseNumber
        completionDate
        createdDate
        description
        expectationDate
        hrRequestSurveyAnswers
        intercomId
        isComplete
        isOverdue
        lastModifiedDate
        status
        subType
        subject
        type
      }
    }
  }
`;

export const SCHEDULED_EVENTS = gql`
  query GetScheduledEvents($input: GetScheduledEventsForUserInput!) {
    getScheduledEventsForUser(input: $input) {
      id
      agent {
        avatarUrl
        id
        name
        role
      }
      attendeeEmails
      missedCallFeeStatus
      title
      calendlyEventId
      startTime
      endTime
      eventLocation
      videoConferenceLink
      phoneNumber
      calendlyInviteeId
      videoConferenceLink
      cancelled
      kind
      callSummaries {
        actionItems
        agent {
          avatarUrl
          id
          name
          role
        }
        approvedAt
        callDurationInMinutes
        createdAt
        followUps
        isApproved
        summary
      }
    }
  }
`;

export const GET_RESCHEDULE_LINK = gql`
  query GetRescheduleLink($input: GetCalendlyRescheduleLinkInput!) {
    getRescheduleLink(input: $input)
  }
`;

export const GET_HR_REQUEST_EXTENDED_DETAIL = gql`
  query HrRequestExtendedDetail($input: GetHrRequestExtendedDetailByIdInput!) {
    hrRequestExtendedDetail(input: $input) {
      caseId
      intercomId
      caseNumber
      type
      subType
      status
      expectationDate
      lastModifiedDate
      createdDate
      completionDate
      isComplete
      isOverdue
      subject
      description
      hrRequestSurveyAnswers
      createdBy
      summary
      agent {
        id
        name
        avatarUrl
        role
      }
      ownerId
      timeline {
        eventType
        eventTime
        oldValue
        newValue
      }
      scheduledEvents {
        id
        calendlyEventId
      }
      companyId
      policies {
        id
        name
        displayName
        description
        icon
        step
        status
        createdAt
        kind
      }
      aiSummary
      aiMetadata
      relatedCases {
        caseId
        subject
      }
    }
  }
`;

export const GET_ASK_NICELY_WEB_SURVEY_INFO = gql`
  query GetAskNicelyWebSurveyInfo($input: GetAskNicelyWebSurveyInfoInput!) {
    getAskNicelyWebSurveyInfo(input: $input) {
      emailHash
      force
      show
    }
  }
`;

export const CLOSE_HR_REQUEST = gql`
  mutation CloseHrRequest($input: CloseHrRequestInput!) {
    closeHrRequest(input: $input) {
      id
      caseId
      conversationId
      intercomConversationId
      customerCompanyId
      createdAt
      aiGenerated
      aiArtifacts
      threadId
    }
  }
`;

export const SET_CASE_STATUS = gql`
  mutation SetCaseStatus($input: SetCaseStatusInput!) {
    setCaseStatus(input: $input) {
      id
      caseId
      conversationId
      intercomConversationId
      customerCompanyId
      createdAt
      aiGenerated
      aiArtifacts
      threadId
    }
  }
`;
