import { computed, ref } from '@nuxtjs/composition-api';
import {
  BillingPeriod,
  PricingPackage,
  PricingPackageType,
  ProductKey,
  useGetLatestPricingPackagesForTierQuery,
  useUpgradeCorePlanMutation,
} from '@/gql/generated';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentPlan from '../useCurrentPlan/useCurrentPlan';
import useNotifications from '@bambeehr/use-notifications';
import bam from '@/lib/bam';
import HrGuidanceEvents from '@/modules/HrGuidance/constants/events';
import useCurrentCompany from '../useCurrentCompany';
import IPlan from '@@/types/plans';
import useCurrentUser from '../useCurrentUser';
import { saveInLocalStorage } from '@/utils/localStorage';

export enum PlanKeys {
  LITE = 'LITE',
  GUIDANCE = 'CORE', // Currently Core ("dedicated") is being mapped to Guidance
  ELITE = 'ELITE',
}

export enum PlanLabels {
  LITE = 'Lite',
  GUIDANCE = 'Guidance', // Currently Core ("dedicated") is being mapped to Guidance
  ELITE = 'Elite',
}

let init;

const ELITE_PURCHSED_DURING_THREAD_KEY = 'elite-purchased-during-thread';

const showModal = ref(false);
const isLoading = ref(false);
const tierNumber = ref<number>();
const companyId = ref<string>();
const currentPlanId = ref<string>();
const currentUserId = ref<string>();
const allPackagesAtTier = ref<PricingPackage[]>([]);

const setupHook = () => {
  const {
    tierNumber: tierNumberFromCurrentPlan,
    currentPlan: currentPlanFromCurrentPlan,
  } = useCurrentPlan();
  const { companyId: companyIdFromCurrentCompany } = useCurrentCompany();
  const { currentUser } = useCurrentUser();

  companyId.value = companyIdFromCurrentCompany.value;
  tierNumber.value = tierNumberFromCurrentPlan.value;
  currentPlanId.value = currentPlanFromCurrentPlan.value?.value;
  currentUserId.value = currentUser.value?._id;
  isLoading.value = true;
  if (!tierNumber.value) {
    // TEOTODO: error handling here
    return;
  }

  const { onResult, onError } = useApolloQuery(
    useGetLatestPricingPackagesForTierQuery,
    {
      tierNumber: tierNumber.value,
      getLatestPricingPackagesForTierInput: {
        billingPeriods: [BillingPeriod.Month],
        productKeys: [ProductKey.Core, ProductKey.Elite], // TEOTODO: replace core with guidance once available
        // productKeys: [ProductKey.Elite], // TEOTODO: replace core with guidance once available
      },
    }
  );

  onResult(({ getLatestPricingPackagesForTier: res }) => {
    allPackagesAtTier.value = res?.packages as PricingPackage[];
  });

  onError((error) => {
    console.error('error', error);
  });
};

interface PricePoint {
  billingPeriod: BillingPeriod;
  monthlyPrice: number;
  priceId: string;
}

type PlanNameToPricePoints = {
  [key in PlanKeys]: PricePoint[];
};

const mapPlanToPricePoints = computed(() => {
  return allPackagesAtTier.value[0]?.plans.reduce((acc, plan) => {
    if (!plan) {
      return acc;
    }

    if (!acc[plan.productKey]) {
      acc[plan.productKey] = [
        {
          billingPeriod: plan.billingPeriod,
          monthlyPrice: plan.monthlyPrice,
          priceId: plan.priceId,
        },
      ];
    } else {
      acc[plan.productKey].push({
        billingPeriod: plan.billingPeriod,
        monthlyPrice: plan.monthlyPrice,
        priceId: plan.priceId,
      });
    }

    return acc;
  }, {} as PlanNameToPricePoints);
});

const mapPlanToMonthlyPrice = computed(() => {
  return allPackagesAtTier.value[0]?.plans.reduce((acc, plan) => {
    if (!plan) {
      return acc;
    }

    acc[plan.productKey] = {
      billingPeriod: plan.billingPeriod,
      monthlyPrice: plan.monthlyPrice,
      priceId: plan.priceId,
      planId: plan.value,
    };

    return acc;
  }, {});
});

const toggleModal = () => {
  showModal.value = !showModal.value;
};

const isPurchasing = ref(false);
const subscribeToPlan = async (planId: string, threadId?: string) => {
  const { addSuccess, addError } = useNotifications();
  if (!companyId.value) {
    addError('Failed to upgrade plan');

    return false;
  }

  const { mutate, onDone, onError } = useApolloMutation(
    useUpgradeCorePlanMutation,
    {
      pending: isPurchasing,
    }
  );

  mutate({
    data: {
      newPlanId: planId,
      companyId: companyId.value,
      // This is no longer used, but expected in the contract
      // Leaving until  we deprecate
      newPricingPackageType: PricingPackageType.Business,
    },
  });

  onDone(({ upgradeCorePlan: res }) => {
    if (res) {
      addSuccess('Your plan has been updated!');

      // TODO: This modal will eventually be used outside of just guidance
      bam.trackEvent(HrGuidanceEvents.SELF_SERVE_PLAN_UPGRADE_SUCCESS, {
        currentPlanId: currentPlanId.value,
        newPlanId: planId,
      });

      if (threadId) {
        console.log('threadId', threadId);
        saveInLocalStorage(
          `${ELITE_PURCHSED_DURING_THREAD_KEY}:${threadId}`,
          true
        );
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }

    return false;
  });

  onError((error) => {
    bam.trackEvent(HrGuidanceEvents.SELF_SERVE_PLAN_UPGRADE_ERROR, {
      currentPlanId: currentPlanId.value,
      newPlanId: planId,
    });
    addError(`Failed to upgrade plan : ${error}`);
  });

  return false;
};

const usePlanUpgrade = () => {
  if (!init) {
    setupHook();
    init = true;
  }

  return {
    isLoading,
    allPackagesAtTier,
    mapPlanToPricePoints,
    mapPlanToMonthlyPrice,
    showModal,
    toggleModal,
    subscribeToPlan,
  };
};

export default usePlanUpgrade;
