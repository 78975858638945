// -- IMPORTS -- //
import {
  ref,
  useRouter,
  watch,
  computed,
  reactive,
  useContext,
} from '@nuxtjs/composition-api';
import shortId from 'shortid';
import bam from '@/lib/bam';
import { AIUpsellEvents } from '@/modules/AI/constants/events';
import {
  getChat,
  getChatStatus,
  sendMessage,
} from '@/modules/AI/services/hriService';

// -- TYPES & ENUMS -- //
export interface FileUpload {
  name: string;
  fileType: string;
  base64: string;
}

export enum Assistants {
  POLICY = 'policy',
  JOB_DESCRIPTION = 'job-description',
  INCIDENT = 'incident',
  CORRECTIVE_ACTION = 'corrective-action',
  GUIDANCE = 'guidance',
}

// -- CONSTANTS -- //
export const LOCAL_STORAGE_USER_MESSAGE = 'userMessage';
const DEFAULT_MESSAGE = 'Thinking...';

// -- STATE INITIALIZATION -- //
let initialized = false;
let router;
let context;

const getDefaultChatStatus = () => ({
  loadingMessages: [DEFAULT_MESSAGE],
  updatedAt: new Date(),
});

// -- REACTIVE STATE -- //
const userMessageState = reactive({
  inputText: '',
  artifactId: '',
  artifactNote: '',
  threadId: '',
  thread: [{}],
});

const sessionID = ref();
const isLoading = ref(false);
const hasSendError = ref(false);
const hiddenUserMessage = ref();
const response = ref();
const showRequestChanges = ref(false);
const shouldShowTile = ref(false);
const shouldShowVerified = ref(false);
const solidifyText = ref(false);
const shouldFadeIn = ref(false);
const animateArtifact = ref(false);
const currentThread = ref();
const chatStatus = ref();
const draftingPhrases = ref<string[]>([]);
const hasEnteredDraftState = ref(false);

const lastMessage = reactive({
  content: '',
  isHidden: false,
});

// -- HELPER FUNCTIONS -- //
const convertThreadToMessages = (thread: any) => {
  return thread
    .map((message: any) => {
      let parsedMessage;

      try {
        parsedMessage = JSON.parse(message.content[0].text.value);
      } catch (e) {
        parsedMessage = message.content[0].text.value;
      }

      return {
        text: {
          content:
            parsedMessage.message ||
            parsedMessage.content ||
            parsedMessage.question,
          example: parsedMessage.sample,
          affirmation: parsedMessage.affirmation,
          options: parsedMessage.options,
          context: parsedMessage.context,
        },
        isAI: message.role === 'assistant',
        isHidden: message.metadata.hidden === 'true',
        isArtifact: !!message.metadata.artifact,
        artifactId: message.metadata.value,
      };
    })
    .reverse();
};

const filterMessages = (messages: any[]) => {
  return messages.filter((message) => {
    return (
      (message.role === 'assistant' || message.role === 'user') &&
      message.metadata.hidden !== 'true'
    );
  });
};

// -- COMPUTED PROPERTIES -- //
const currentAssistant = computed(() => response.value?.assistant || '');

const artifactTypeName = computed(() => {
  switch (currentAssistant.value) {
    case Assistants.POLICY:
      return 'Policy';
    case Assistants.JOB_DESCRIPTION:
      return 'Job Description';
    case Assistants.CORRECTIVE_ACTION:
      return 'Corrective Action';
    case Assistants.INCIDENT:
      return 'Report';
    case Assistants.GUIDANCE:
      return 'Guidance';
    default:
      return '';
  }
});

const artifactTitle = computed(() => {
  switch (currentAssistant.value) {
    case Assistants.POLICY:
      return response.value?.artifact?.policy?.title || 'your New Policy';
    case Assistants.JOB_DESCRIPTION:
      return (
        response.value?.artifact?.jobDescription?.title ||
        'your Job Description'
      );
    case Assistants.CORRECTIVE_ACTION:
      return response.value?.artifact?.cap?.title || 'your Corrective Action';
    case Assistants.INCIDENT:
      return (
        response.value?.artifact?.incidents?.title || 'your Incident Report'
      );
    case Assistants.GUIDANCE:
      return response.value?.artifact?.actionPlan?.title || 'your HR Guidance';
    default:
      return '';
  }
});

const isCertified = computed(
  () => response.value?.artifact?.policy?.confidence >= 80
);
const employeeId = computed(
  () =>
    response.value?.artifact?.correctiveAction?.employeeId ||
    response.value?.artifact?.jobDescription?.employeeId
);
const employeeList = computed(
  () => response.value?.artifact?.jobDescription?.assignments || []
);

const currentArtifact = computed(() => {
  switch (currentAssistant.value) {
    case Assistants.POLICY:
      return response.value?.artifact?.policy;
    case Assistants.JOB_DESCRIPTION:
      return response.value?.artifact?.jobDescription;
    case Assistants.CORRECTIVE_ACTION:
      return response.value?.artifact?.correctiveAction;
    case Assistants.INCIDENT:
      return response.value?.artifact?.incidents;
    case Assistants.GUIDANCE:
      return response.value?.artifact?.actionPlan;
    default:
      return null;
  }
});

const currentArtifactList = computed(() => {
  return response.value?.artifact;
});

const bestPracticeSummaryArtifact = computed(() => {
  return response.value?.artifact?.bestPracticeSummary;
});

const comprehensiveGuidanceArtifact = computed(() => {
  return response.value?.artifact?.comprehensiveGuidance;
});

const caseId = computed(() => currentArtifact.value?.caseId || '');
const currentArtifactMarkup = computed(() => currentArtifact.value?.html || '');
const currentGuidanceDto = computed(() => {
  try {
    const dto = JSON.parse(currentArtifact.value?.dto || '{}');

    return dto.guidance || '';
  } catch (e) {
    return '';
  }
});
const currentArtifactId = computed(() => currentArtifact.value?.id || '');
const isEditingartifact = computed(
  () => currentArtifactMarkup.value && isLoading.value
);
const showArtifact = computed(() => !!currentArtifactMarkup.value);
const placeholder = computed(() => response.value?.next?.sample || '');
const canUploadFiles = computed(
  () => response.value?.next?.acceptFileUpload ?? false
);
const activeThread = computed(() =>
  convertThreadToMessages(filterMessages(response.value?.messages || []))
);

const allThinkingPhrases = computed(
  () => chatStatus.value?.loadingMessages || []
);
const currentThinkingPhrase = computed(() => {
  return (
    chatStatus.value?.updatedAt &&
    allThinkingPhrases.value[
      Math.floor(Math.random() * chatStatus.value.loadingMessages.length)
    ]
  );
});

const isDraftingArtifact = computed(() =>
  [
    'pre_policy_change_requested',
    'post_policy_change_requested',
    'pre_policy_drafted',
    'post_policy_drafted',
    'pre_job_description_drafted',
    'post_job_description_drafted',
    'pre_job_description_change_requested',
    'post_job_description_change_requested',
    'pre_corrective_action_drafted',
    'post_corrective_action_drafted',
    'pre_corrective_action_change_requested',
    'post_corrective_action_change_requested',
    'pre_incident_drafted',
    'post_incident_drafted',
    'pre_incident_change_requested',
    'post_incident_change_requested',
    'pre_action_plan_drafted',
    'post_action_plan_drafted',
    'pre_action_plan_change_requested',
    'post_action_plan_change_requested',
    'pre_summary_drafted',
    'post_summary_drafted',
    'policy_drafted',
    'summary_drafted',
  ].includes(chatStatus.value?.status)
);

// -- WATCHERS -- //
watch(
  isDraftingArtifact,
  (draftState) => {
    if (draftState) {
      hasEnteredDraftState.value = true;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  currentThinkingPhrase,
  (newPhrase) => {
    if (
      newPhrase &&
      hasEnteredDraftState.value &&
      newPhrase !== DEFAULT_MESSAGE
    ) {
      draftingPhrases.value.push(newPhrase);
    }
  },
  { immediate: true }
);

// -- CORE FUNCTIONALITY -- //
const getStatus = async (threadId: any, shouldReset: boolean = false) => {
  if (shouldReset) {
    chatStatus.value = getDefaultChatStatus();
  }
  try {
    const res = await getChatStatus(context.$axios, threadId);
    chatStatus.value = {
      ...res,
      updatedAt: new Date(),
    };
  } catch (e) {
    console.log(e);
  }
};

const getChatOverview = async (threadId: any) => {
  isLoading.value = true;
  const res = await getChat(context.$axios, threadId);
  response.value = res;
  isLoading.value = false;
};

const chatWithAssistant = async (
  threadId?: string,
  assistant?: string,
  files?: File[]
) => {
  hasSendError.value = false;
  isLoading.value = true;
  const skipRun = !!(
    !(userMessageState.inputText || hiddenUserMessage.value) && threadId
  );
  lastMessage.content = userMessageState.inputText;
  lastMessage.isHidden = !!hiddenUserMessage.value;

  let statusInterval;

  const runChecks = (shouldReset?) => {
    getStatus(threadId, shouldReset);
  };

  if (threadId) {
    runChecks(true);
    statusInterval = setInterval(
      runChecks,
      hasEnteredDraftState.value ? 6000 : 4000
    );
  } else {
    sessionID.value = shortId.generate();
    bam.trackEvent(AIUpsellEvents.AI_REQUEST_START, {
      type: `${assistant || currentAssistant.value}`,
      sessionID: sessionID.value,
      thread: activeThread.value.length
        ? activeThread.value
        : [userMessageState.inputText || hiddenUserMessage.value],
    });
  }

  const { $axios } = context;

  const content =
    userMessageState.inputText || hiddenUserMessage.value
      ? JSON.stringify({
          content: hiddenUserMessage.value || userMessageState.inputText,
        })
      : undefined;

  try {
    const res = await sendMessage($axios, {
      content,
      threadId,
      assistant,
      files,
      skipRun,
      hidden: !!hiddenUserMessage.value,
    });

    hasSendError.value = false;
    response.value = res;
    userMessageState.threadId = response.value?.threadId;
    currentThread.value = response.value?.threadId || '';
    userMessageState.inputText = '';

    if (!userMessageState.inputText && !threadId) {
      router.push(`/create-request/${res?.threadId}`);
    } else {
      isLoading.value = false;
    }

    userMessageState.thread = convertThreadToMessages(res?.messages) || [];
    clearInterval(statusInterval);
  } catch (err) {
    isLoading.value = false;
    userMessageState.inputText = '';
    hasSendError.value = true;
    console.log(err);
    clearInterval(statusInterval);
    bam.trackEvent(AIUpsellEvents.AI_REQUEST_ERROR, {
      type: currentAssistant.value,
      sessionID: sessionID.value,
      thread: activeThread.value.length ? activeThread.value : [content],
    });
  }
};

const handleSendMessage = (
  message?: string | undefined,
  asstId?: string | undefined,
  files?: File[] | undefined
) => {
  if (!userMessageState.inputText && !message) {
    return;
  }
  hiddenUserMessage.value = message;
  isLoading.value = true;

  chatWithAssistant(
    currentThread.value,
    asstId || currentAssistant.value,
    files
  );
  userMessageState.inputText = '';
  hiddenUserMessage.value = '';
};

const resetHook = () => {
  response.value = null;
  currentThread.value = '';
  isLoading.value = false;
  userMessageState.inputText = '';
  userMessageState.artifactId = '';
  userMessageState.threadId = '';
  hasEnteredDraftState.value = false;
  draftingPhrases.value = [];
  animateArtifact.value = false;
  shouldShowTile.value = false;
  shouldFadeIn.value = false;
  hiddenUserMessage.value = '';
  chatStatus.value = null;
  lastMessage.content = '';
  lastMessage.isHidden = false;
  shouldShowVerified.value = false;
  sessionID.value = '';
};

const setupHook = () => {
  router = useRouter();
};

// -- HOOK EXPORT -- //
const useAIConversation = () => {
  if (!initialized) {
    setupHook();
    initialized = true;
    context = useContext();
  }

  return {
    // State
    isLoading,
    hasSendError,
    userMessageState,
    showRequestChanges,
    currentThread,
    lastMessage,
    shouldShowTile,
    shouldShowVerified,
    shouldFadeIn,
    animateArtifact,
    solidifyText,
    sessionID,

    // Computed
    currentAssistant,
    showArtifact,
    currentArtifact,
    currentArtifactList,
    bestPracticeSummaryArtifact,
    comprehensiveGuidanceArtifact,
    currentArtifactMarkup,
    currentGuidanceDto,
    currentArtifactId,
    caseId,
    chatStatus,
    isDraftingArtifact,
    hasEnteredDraftState,
    currentThinkingPhrase,
    allThinkingPhrases,
    draftingPhrases,
    isEditingartifact,
    artifactTitle,
    artifactTypeName,
    employeeId,
    isCertified,
    employeeList,
    canUploadFiles,
    activeThread,
    placeholder,

    // Methods
    handleSendMessage,
    resetHook,
    chatWithAssistant,
    getChatOverview,
  };
};

export default useAIConversation;
